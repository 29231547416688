<template>
    <div>
        <div class="logo mb-4">
            <img src="./../assets/logo-nvfb.png" />
        </div>
        <h1 class="text-2xl">Bekken(bodem) check  Therapeutenomgeving</h1>
        <div class="content-center">
            <div class="code-input-box">
                <h2 class="text-3xl font-thin text-secondary-500">Code cli&#235;nt:</h2>
                <form v-on:submit.prevent="submit" class="pt-2 relative mx-auto text-gray-600 w-full">
                    <input class="border-2 border-green-600 bg-white h-16 px-5 pr-16 rounded-lg text-2xl w-1/4 focus:outline-none shadow-md focus:shadow-lg"
                           type="search" name="code" placeholder="Vul hier de code in" v-model="uniqueCode">
                    <button type="submit" class="mt-5 mr-4 -ml-8">
                        <svg class="text-gray-600 h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg"
                             xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
                             viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve"
                             width="512px" height="512px">
                            <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                        </svg>
                    </button>
                </form>
                <p v-if="currentMessage" class="error-message">{{ currentMessage }}</p>
            </div>
        </div>
        <div v-if="report">
            <div class="results-header">
                <h2 class="text-4xl font-thin text-secondary-500 pt-8 pb-3">Antwoorden gegeven door cli&#235;nt:</h2>
            </div>
            <div class="results hover:shadow-xl w-full">
                <div class="question-and-answer">
                    <p class="question">Hoe oud ben je?</p>
                    <p class="answer">{{ report.age }} jaar</p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Hoe vaak ben je bevallen?</p>
                    <p class="answer">{{ report.givenBirthAmount }} keer</p>
                </div>
                <div class="question-and-answer" v-if="report.cesareanAmount">
                    <p class="question">Bij hoe veel bevallingen kreeg je een geplande keizersnede?</p>
                    <p class="answer">{{ report.cesareanAmount }}</p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Hoe lang ben je?</p>
                    <p class="answer">{{ report.length }} cm</p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Hoe veel kilo weeg je op dit moment?</p>
                    <p class="answer">{{ report.weight }} kg</p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Hoe veel kilo woog je toen je zwanger werd?</p>
                    <p class="answer">{{ report.weightBefore }} kg</p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Verlies je wel eens plas of urine zonder dat je dat wilt?</p>
                    <p class="answer"><YesNoInput :value="report.urineLoss" /></p>
                </div>
                <div class="question-and-answer" v-if="report.urineLoss === 'Yes'">
                    <p class="question">Hoe vaak heeft u last van ongewild urineverlies?</p>
                    <p class="answer">{{ report.urineLossOccurance }}</p>
                </div>
                <div class="question-and-answer" v-if="report.urineLoss === 'Yes'">
                    <p class="question">Hoeveel urine verliest u gewoonlijk?</p>
                    <p class="answer">{{ report.urineLossAmount }}</p>
                </div>
                <div class="question-and-answer" v-if="report.urineLoss === 'Yes'">
                    <p class="question">Hoeveel invloed heeft het ongewilde urineverlies op uw dagelijkse leven?</p>
                    <p class="answer"><Scale :value="report.urineLossInfluence" /></p>
                </div>
                <div class="question-and-answer" v-if="report.urineLoss === 'Yes'">
                    <p class="question">Wanneer verliest u urine?</p>
                    <p class="answer"><CheckBoxesInput :value="report.urineLossHow" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Verlies jij zo nu en dan poep of ontlasting?</p>
                    <p class="answer"><YesNoInput :value="report.fecesLoss" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Verlies jij wel eens een windje, terwijl je dat niet wilt?</p>
                    <p class="answer"><YesNoInput :value="report.gasLoose" /></p>
                </div>
                <div class="question-and-answer" v-if="report.fecesLoss === 'Yes' || report.gasLoose === 'Yes'">
                    <p class="question">Hoe vaak verliest u ongewild vaste ontlasting?</p>
                    <p class="answer"><Slider :value="report.rockSolidFeces" /></p>
                </div>
                <div class="question-and-answer" v-if="report.fecesLoss === 'Yes' || report.gasLoose === 'Yes'">
                    <p class="question">Hoe vaak verliest u ongewild vloeibare ontlasting?</p>
                    <p class="answer"><Slider :value="report.unwantedFeces" /></p>
                </div>
                <div class="question-and-answer" v-if="report.fecesLoss === 'Yes' || report.gasLoose === 'Yes'">
                    <p class="question">Hoe vaak verliest u gas/windjes?</p>
                    <p class="answer"><Slider :value="report.losingGas" /></p>
                </div>
                <div class="question-and-answer" v-if="report.fecesLoss === 'Yes' || report.gasLoose === 'Yes'">
                    <p class="question">Hoe vaak gebruikt u beschermend materiaal voor ontlastingsverlies?</p>
                    <p class="answer"><Slider :value="report.usingAid" /></p>
                </div>
                <div class="question-and-answer" v-if="report.fecesLoss === 'Yes' || report.gasLoose === 'Yes'">
                    <p class="question">Hoe vaak belemmert het ontlastingsverlies uw dagelijkse leven?</p>
                    <p class="answer"><Slider :value="report.dailyObstruction" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Voelde je of zag je een bobbel bij je schede of vagina tijdens je zwangerschap?</p>
                    <p class="answer"><YesNoInput :value="report.bumpBefore" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Voel je of zie je nu een bobbel bij je schede of vagina?</p>
                    <p class="answer"><YesNoInput :value="report.bumpNow" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Doe je zwaar lichamelijk werk?</p>
                    <p class="answer"><YesNoInput :value="report.heavyWork" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Heb je pijn in en/of rond je bekken?</p>
                    <p class="answer"><YesNoInput :value="report.pelvisPain" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Beperkt de pijn in en/of rond je bekken je dagelijkse leven?</p>
                    <p class="answer"><Scale :value="report.feelingScale" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Moest je bevallen met een tang of vacuumpomp?</p>
                    <p class="answer"><YesNoInput :value="report.vacuumBirth" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Woog minstens een van je kinderen bij de geboorte 4000 gram of meer?</p>
                    <p class="answer"><YesNoInput :value="report.heavyBaby" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Scheurde je gedeeltelijk of geheel uit tijdens de bevalling?</p>
                    <p class="answer"><YesNoInput :value="report.rupture" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Verloor je zonder dat je dat wilde plas of urine in de tweede helft van je zwangerschap?</p>
                    <p class="answer"><YesNoInput :value="report.secondHalfUrineLoss" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Verloor je zonder dat je het wilde poep of ontlasting tijdens je zwangerschap?</p>
                    <p class="answer"><YesNoInput :value="report.unwantedFecesPregnancy" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Was je dertig jaar of ouder toen je beviel?</p>
                    <p class="answer"><YesNoInput :value="report.olderDelivery" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Had je voordat je zwanger werd pijn in je onderrug of in je bekken?</p>
                    <p class="answer"><YesNoInput :value="report.prePregnancyPain" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Deed je bekken pijn tijdens de zwangerschap?</p>
                    <p class="answer"><YesNoInput :value="report.pregnancyPelvisPain" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Heb je last van verstopping? Kun je moeilijk naar de wc?</p>
                    <p class="answer"><YesNoInput :value="report.blockageNow" /></p>
                </div>
                <div class="question-and-answer">
                    <p class="question">Was de bevalling ingeleid?</p>
                    <p class="answer"><YesNoInput :value="report.preludeLabor" /></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CheckBoxesInput from './Answer/CheckBoxesInput';
import Scale from './Answer/Scale';
import Slider from './Answer/Slider';
import YesNoInput from './Answer/YesNoInput';

export default {
    name: 'Home',
    components: {
        CheckBoxesInput,
        Scale,
        Slider,
        YesNoInput
    },
    data: () => {
        return {
            uniqueCode: null,
            currentMessage: '',
            report: null
        };
    },
    methods: {
        translatedBoolean(value) {
            if (value === 'No') {
                return 'Nee';
            }
            if (value === 'Yes') {
                return 'Ja';
            }
            return '';
        },
        async submit() {
            if (this.uniqueCode === null || this.uniqueCode === '') {
                this.currentMessage = 'U heeft nog geen code opgegeven.';
                return;
            }
            this.currentMessage = null;

            if (this.$store.state.reports[this.uniqueCode]) {
                this.report = this.$store.state.reports[this.uniqueCode];
                return;
            }

            let response = await fetch(
                '/api.php',
                {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        action: 'fetch-report',
                        uniqueCode: this.uniqueCode,
                        csrfToken: this.$store.state.csrfToken
                    })
                }
            );

            if (response.status === 200) {
                let result = await response.json();
                this.$store.commit('updateCsrfToken', result.csrfToken);
                delete result['updateCsrfToken'];
                this.$store.commit('storeReport', {
                    code: this.uniqueCode,
                    report: result
                });
                this.report = result;
            } else if (response.status === 410) {
                this.currentMessage = 'Deze code is reeds opgevraagd en kan niet meer weergegeven worden.';
                this.report = null;
            } else if (response.status === 404) {
                this.currentMessage = 'Code niet gevonden';
                this.report = null;
            } else {
                this.currentMessage = 'Er is een fout opgetreden, herlaad de pagina en probeer het nog eens.';
                this.report = null;
            }
        }
    }
}
</script>

<style scoped>
img {
    @apply mx-auto;
    @apply mt-4;
    @apply w-1/2;
}

@screen xl {
    img {
        @apply w-1/5;
    }
}

.code-input-box h1 {
    @apply text-primary-500;
    @apply text-6xl;
    @apply font-thin;
    @apply mx-auto;
}

.code-input-box {
    @apply w-full;
    @apply mt-6;
}

.error-message {
    @apply text-alert-500;
    @apply text-sm;
    @apply italic;
    padding: .1rem 0 .5rem 1rem;
}

.results {
    @apply border-2;
    @apply border-green-600;
    @apply w-2/3;
    @apply mx-auto;
    @apply rounded-lg;
    @apply mt-2;
    @apply p-4;
    @apply bg-gray-100;
    @apply shadow-md;
    @apply mb-16;
}

@screen print {
    .results {
        @apply w-full;
    }
}

ul {
    @apply mt-4;
}

.question-and-answer {
    @apply flex;
    @apply flex-row;
    @apply justify-between;
    @apply w-full;
    @apply text-left;
    @apply h-20;
}

.question {
    @apply w-2/3 p-2;
}

.answer {
    @apply w-1/3 p-2;
}
</style>
