<template>
    <span>{{ value }} uit 10</span>
</template>

<script>
export default {
    props: {
        value: Number
    }
}
</script>
