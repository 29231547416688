<template>
    <span v-html="answer"></span>
</template>

<script>
export default {
    props: {
        value: String
    },
    computed: {
        answer() {
            if (this.value === 'Yes') {
                return 'Ja';
            }
            if (this.value === 'No') {
                return 'Nee';
            }
            return '';
        }
    }
}
</script>
