import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

let csrfMetaTag = document.head.querySelector('meta[name="csrf"]'),
    releaseMetaTag = document.head.querySelector('meta[name="release"]');

const releaseUuid = releaseMetaTag ? releaseMetaTag.getAttribute('content') : null;

let store = new Vuex.Store({
    state: {
        csrfToken: null,
        releaseUuid: releaseUuid,
        reports: {}
    },
    plugins: [
        new VuexPersistence({
            storage: window.localStorage,
            key: 'vuex-' + releaseUuid
        }).plugin
    ],
    mutations: {
        storeFormValue(state, payload) {
            state[payload.name] = payload.value;
        },
        updateCsrfToken(state, payload) {
            state.csrfToken = payload;
        },
        storeReport(state, payload) {
            state.reports[payload.code] = payload.report;
        }
    },
});

store.commit('updateCsrfToken', csrfMetaTag ? csrfMetaTag.getAttribute('content') : null);

export default store;
