<template>
  <div id="app">
      <div class="header"></div>
      <Home />
      <div class="footer"></div>
  </div>
</template>

<script>
import Home from "./components/Home.vue"

export default {
  name: 'app',
  components: {
        Home
  }
}
</script>

<style>
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
.footer {
    @apply bg-secondary-500;
    @apply w-full;
    @apply h-6;
    @apply fixed;
    @apply bottom-0;
}

.header {
    background-color: #009175;
    @apply w-full;
    @apply h-6;
    @apply top-0;
}
</style>
