<template>
    <ul class="p-0 m-0 list-disc ml-4">
        <li v-bind:key="i" v-for="(item, i) in value" v-html="item"></li>
    </ul>
</template>

<script>
export default {
    props: {
        value: Array
    }
}
</script>
