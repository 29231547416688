<template>
    <span v-html="answer"></span>
</template>

<script>
export default {
    props: {
        value: String
    },
    computed: {
        answer() {
            if (this.value === 'never') {
                return 'Nooit';
            }
            if (this.value === 'rare') {
                return 'Minder dan 1x per maand';
            }
            if (this.value === 'sometimes') {
                return 'Minder dan 1x per week';
            }
            if (this.value === 'often') {
                return '1x Per week of vaker';
            }
            if (this.value === 'always') {
                return '1x Per dag of vaker';
            }
            return '';
        }
    }
}
</script>
